import React from 'react';
import Slider from 'react-slick';
import './Testimonial.css'; // Archivo de estilos
import tw from "twin.macro";

const Container = tw.div`relative py-8 px-4 sm:px-0`;
export const Header = tw.h1`text-3xl sm:text-4xl md:text-5xl lg:text-5xl font-normal leading-none uppercase text-center `;
const HeaderContainer = tw.div`mt-2 mb-2 w-full flex flex-col`;
const VerticalSpacer = tw.div`mt-10 w-full`
const Testimonial = tw.div`px-6 py-8 sm:px-20 sm:py-16 focus:outline-none flex! flex-col justify-between h-96 sm:h-full items-center sm:items-start max-w-4xl mx-auto border bg-gray-200`
const QuoteContainer = tw.div`relative`
const Quote = tw.blockquote`font-medium sm:font-normal relative text-sm sm:text-xl text-center sm:text-left`
const CustomerInfoAndControlsContainer = tw.div`mt-8 flex items-center flex-col sm:flex-row justify-center text-center sm:text-left`
const CustomerImage = tw.img`w-16 h-16 rounded-full `
const CustomerNameAndProfileContainer = tw.div`mt-4 sm:mt-0 sm:ml-4 flex flex-col`
const CustomerName = tw.span`text-lg font-semibold `

const testimonials = [
    {
        id: 1,
        name: 'Sarah Williams',
        company: 'TechSolutions LLC',
        image:
            "https://images.unsplash.com/photo-1491349174775-aaafddd81942?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2.85&w=256&h=256&q=80",
        message: 'I was really impressed with the quality of the service provided by Timeless Tile Inc. Their team was professional, efficient, and friendly. I would definitely recommend them to anyone looking for tile installation services.',
    },
    {
        id: 2,
        name: 'David Chen',
        company: 'Chen Consulting',
        image:
            "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.85&w=256&h=256&q=80",
        message: 'I recently worked with Timeless Tile Inc. on a bathroom renovation project, and I have to say I was thoroughly impressed with their work. Their attention to detail and dedication to quality really stood out. I would definitely use them again in the future.',
    },
    {
        id: 3,
        name: 'Karen Davis',
        company: 'Wong & Associates',
        image:
            "https://images.unsplash.com/photo-1567532939604-b6b5b0db2604?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2.85&w=256&h=256&q=80",
        message: 'I had a great experience working with Timeless Tile Inc. on my kitchen renovation project. They were very responsive to my needs and helped me select the perfect tiles to fit my design vision. Their team was professional, reliable, and easy to work with. Highly recommend!',
    },
];


const Portfolio = () => {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        centerMode: true,
        autoplaySpeed: 5000,
        cssEase: 'linear',
        arrows: true,
        dots: true,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                },
            },
        ],
    };
    return (
        <Container>
            <div className='teamSlider text-center'>
                <div className="other-text">
                    <HeaderContainer>
                        <Header>Testimonials</Header>
                    </HeaderContainer>
                </div>
            </div>
            <VerticalSpacer>
                <Slider {...settings}>
                    {testimonials.map((testimonial, index) => (
                        <Testimonial key={index}>
                            <QuoteContainer>
                                <Quote>
                                    {testimonial.message}
                                </Quote>
                            </QuoteContainer>
                            <CustomerInfoAndControlsContainer>
                                <CustomerImage src={testimonial.image} loading='lazy' alt='testimonial-profile-photo'/>
                                <CustomerNameAndProfileContainer>
                                    <CustomerName>
                                        {testimonial.name}
                                    </CustomerName>
                                </CustomerNameAndProfileContainer>

                            </CustomerInfoAndControlsContainer>
                        </Testimonial>
                    ))}
                </Slider>
            </VerticalSpacer>
        </Container>
    );
};

export default Portfolio;