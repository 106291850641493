import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import Gil from "images/gil.webp"

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-8 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div`xl:mr-12 xl:last:mr-0`;
const HeadingColumn = styled(Column)(props => [
  tw`w-full xl:w-5/12`,
  props.textOnLeft ? tw`xl:order-first` : tw`xl:order-last xl:ml-12 xl:mr-0`
]);
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-3/12 mt-16 xl:mt-0 xl:last:ml-auto`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;

const Heading = tw.h1`text-3xl sm:text-5xl md:text-6xl lg:text-5xl font-normal leading-none uppercase`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;

const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center `
]);

const Actions = styled.div`
  ${tw`mb-8 mt-8 md:mt-8 lg:mb-0  mx-2 sm:mx-0`}
  .action {
    ${tw`text-center text-gray-800 sm:w-48 py-4 px-2 font-medium tracking-wide hocus:outline-none focus:shadow-outline transition duration-300 inline-block flex items-center justify-center`}
  }
  .primaryAction {
    ${tw`border border-gray-900 bg-white text-gray-800 hover:bg-gray-900 hover:text-gray-100 font-semibold`}
  }
  svg {
    ${tw`ml-2 w-4 h-4`}
  }
`;
export default ({
  subheading = "Our Portfolio",
  headingHtmlComponent = (
    <>
      We've done some amazing projects
    </>
  ),
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam.",
  linkText = "View all Projects",
  cardLinkText = "Read Case Study",
  textOnLeft = false
}) => {
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1601719817866-8678c3d417e5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80",
      company: "Tesla Inc.",
      type: "Ad Campaign",
      title: "Personalized Ad Campaign using Google AdWords",
      durationText: "90 Days Campaign",
      locationText: "New York"
    },
    {
      imageSrc: Gil,
      company: "Nestle",
      type: "SEO Marketing",
      title: "Ranking #1 for keywords like Chocolate, Snack",
      durationText: "180 Day Campaign",
      locationText: "Palo Alto"
    }
  ];
  return (
    <Container>
      <Content>
        <ThreeColumn>
          <HeadingColumn textOnLeft={textOnLeft}>
            <HeadingInfoContainer>
              <Heading>{headingHtmlComponent}</Heading>
              <HeadingDescription>{description}</HeadingDescription>
              <Actions>
              <a href={'/projects'} className="action primaryAction">
                View All Projects
                <ArrowRightIcon/>
              </a>
            </Actions>
            </HeadingInfoContainer>
          </HeadingColumn>
          {cards.map((card, index) => (
            <CardColumn key={index}>
              <Card>
                <CardImage imageSrc={card.imageSrc} alt={card.locationText} loading="lazy"/>
              </Card>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
