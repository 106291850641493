import React from "react";
import { ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Subheading as SubheadingBase } from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import CocinaBlanca from "images/Cocina-blanca.webp"
import RakuMustard from "images/RakuMustard.webp"
import PassionAzul from "images/PassionAzul.webp"
import SquareTile from "images/squareTile.webp"

const HeadingContainer = tw.div`text-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Container = tw.div`relative py-8 px-4 sm:px-0`;
export const Header = tw.h1`text-3xl sm:text-4xl md:text-5xl lg:text-5xl font-normal leading-none uppercase text-center `;
const Description = tw(SectionDescription)`mx-auto`;
const Posts = tw.div`flex flex-wrap -mr-3 relative`;
const Post = tw.a`flex flex-col h-full bg-gray-200 `;
const PostImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 sm:h-80 bg-center bg-cover `}
`;
const PostText = tw.div`flex-1 px-6 py-8`
const PostTitle = tw.h6`font-medium uppercase hover:underline transition duration-300 `;
const PostTitleCentered = tw.h6`font-medium uppercase text-center hover:underline transition duration-300 `;

const PostType = tw.h6`font-medium uppercase hover:underline transition duration-300 text-xs mb-2`;
const PostTypeCentered = tw.h6`font-medium uppercase text-center hover:underline transition duration-300 text-xs mb-2`;

const PostDescription = tw.p``;
const AuthorInfo = tw.div`flex`;
const AuthorName = tw.div`font-medium uppercase transition duration-300 text-xs my-2 text-center`;

const PostContainer = styled.div`
  ${tw`relative z-1 mt-10 sm:pt-3 pr-3 w-full sm:w-1/2 lg:w-1/3 max-w-sm mx-auto sm:max-w-none sm:mx-0`}

  ${props => props.featured && css`
    ${tw`w-full sm:w-full lg:w-2/3`}
    ${Post} {
      ${tw`sm:flex-row items-center sm:pr-3`}
    }
    ${PostImage} {
      ${tw`sm:h-80 sm:min-h-full w-full sm:w-1/2 `}
    }
    ${PostText} {
      ${tw`pl-8 pr-5`}
    }
    ${PostTitle} {
      ${tw`text-2xl`}
    }
    ${PostDescription} {
      ${tw`mt-4 text-sm font-semibold text-gray-600 leading-relaxed`}
    }
    ${AuthorInfo} {
      ${tw`mt-8 flex items-center`}
    }
    ${AuthorName} {
      ${tw`text-left text-gray-800 text-sm`}
    }
  `}
`;

export default ({
  subheading = "",
  heading = "We love writing.",
  description = "",
  posts = [
    {
      postImageSrc: CocinaBlanca,
      authorImageSrc:
        "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.95&w=512&h=512&q=80",
      title: "OCTOBER IS KITCHEN & BATH MONTH",
      description:
        "Celebrate the heart of your home by giving it a little extra love this April. From small updates to full renovations, now is the perfect time to create a kitchen and bath that you'll love for years to come.",
      authorName: "Adam Cuppy",
      authorProfile: "Vlogger",
      url: "/",
      featured: true,
      date: 'APRIL 23, 2023',
      type: 'Design & style'
    },
    {
      postImageSrc: PassionAzul,
      title: "STANDOUT TILE SHAPES",
      authorName: "Aaron Patterson",
      url: "/",
      date: 'MARCH 17, 2023',
      type: 'Design & style'
    },
    {
      postImageSrc: RakuMustard,
      title: "Choosing the perfect Safaris in Africa",
      authorName: "Sam Phipphen",
      url: "/",
      date: 'FEBRUARY 8, 2023',
      type: 'Design & style'
    },
    {
      postImageSrc: SquareTile,
      title: "13 BEAUTIFUL KITCHEN BACKSPLASH IDEAS",
      authorName: "Tony Hawk",
      url: "/",
      date: 'JANUARY 12, 2023',
      type: 'Design & style'
    },
    {
      postImageSrc:
        "https://images.unsplash.com/photo-1576698483491-8c43f0862543?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=348&q=80",
      title: "HOW TO CHOOSE QUALITY TILE",
      authorName: "Himali Turn",
      url: "/",
      date: 'DECEMBER 20, 2022',
      type: 'Design & style'
    }
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Header>Timeless Tile Blog</Header>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Posts>
          {posts.map((post, index) => (
            <PostContainer featured={post.featured} key={index}>
              <Post href={post.url}>
                <PostImage imageSrc={post.postImageSrc} alt={post.title} loading="lazy"/>
                <PostText>
                  {!post.featured ? <PostTypeCentered>{post.type}</PostTypeCentered> : <PostType>{post.type}</PostType>}
                  {!post.featured ? <PostTitleCentered>{post.title}</PostTitleCentered> : <PostTitle>{post.title}</PostTitle>}
                  {post.featured && <PostDescription>{post.description}</PostDescription>}
                  <AuthorName>{post.date}</AuthorName>
                </PostText>
              </Post>
            </PostContainer>
          ))}
        </Posts>
      </ContentWithPaddingXl>
    </Container>
  );
};
