import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import marmol from "../../images/marmol.webp"

const Container = tw.div`relative`;
const Content = tw.div``;

const FormContainer = styled.div`
  background-image: url(${marmol});
  ${tw`p-10 sm:p-12 md:p-16 bg-gray-200 text-gray-800 relative `}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between w-full`;
const Column = tw.div` flex flex-wrap w-full  justify-between`;
const InputContainer = tw.div`relative py-5 mt-6 flex w-full sm:w-45`;
const TextAreaContainer = tw.div`relative py-5 mt-6 w-full`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-medium text-sm text-gray-800 uppercase `;
const Input = tw.input`border-2 p-4 mt-2 w-full appearance-none border-gray-800 bg-white text-gray-800`;
const TextArea = tw.textarea` border-2 h-24 sm:h-full resize-none w-full appearance-none border-gray-800 bg-white`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 border border-gray-100 text-gray-100 font-bold tracking-wide  uppercase text-sm transition duration-300 bg-gray-900 transform hover:bg-gray-100 hover:text-gray-800  mx-auto`;

export const Header = tw.h1`text-3xl sm:text-4xl md:text-5xl lg:text-5xl font-normal leading-none uppercase text-center `;

export default () => {
  return (
    <Container id="contact">
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            
         <Header>Get in Touch</Header>
            <form action="#">
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Name</Label>
                    <Input id="name-input" type="text" name="name" />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="surname-input">Last Name</Label>
                    <Input id="surname-input" type="surname" name="surname"/>
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">E-mail</Label>
                    <Input id="email-input" type="email" name="email" />
                  </InputContainer>
                  
                  <InputContainer>
                    <Label htmlFor="phone-input">Phone</Label>
                    <Input id="phone-input" type="phone" name="phone" />
                  </InputContainer>
                  
                  
                  <TextAreaContainer>
                    <Label htmlFor="message-input">Message</Label>
                    <TextArea id="message-input" type="message" name="message" />
                  </TextAreaContainer>
                </Column>
              </TwoColumn>

              <SubmitButton type="submit" value="Submit">Submit</SubmitButton>
            </form>
          </div>
        </FormContainer>
      </Content>
    </Container>
  );
};
