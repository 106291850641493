import React from 'react';
import Slider from 'react-slick';

import './TeamCarousel.css'; // Archivo de estilos
import portfolio01 from 'images/portfolio/portfolio-02.webp'
import portfolio03 from 'images/portfolio/portfolio-03.webp'
import portfolio04 from 'images/portfolio/portfolio-04.webp'
import portfolio05 from 'images/portfolio/portfolio-05.webp'
import portfolio06 from 'images/portfolio/portfolio-06.webp'
import portfolio07 from 'images/portfolio/portfolio-07.webp'

import tw from "twin.macro";
import styled from "styled-components";
import { motion } from "framer-motion";
;
const Container = tw.div`relative py-8 px-4 sm:px-0 sm:pt-0`;
export const Header = tw.h1`text-3xl sm:text-4xl md:text-5xl lg:text-5xl font-normal leading-none uppercase text-center `;
const HeaderContainer = tw.div`m-0 sm:mt-2 sm:mb-2 w-full flex flex-col `;
const VerticalSpacer = tw.div`mt-10 w-full`

const Image = styled(motion.div)(props => [
  `background-image: url("${props.$imageSrc}");`,
  tw`h-96 bg-cover bg-center mx-3 flex items-center justify-center`
]);

const teamMembers = [
  {
    id: 1,
    image: portfolio01,
    title: 'Kitchen Tile'
  },
  {
    id: 3,
    image: portfolio03,
    title: 'Kitchen Tile'
  },
  {
    id: 4,
    image: portfolio04,
    title: 'Floor Tile'
  },
  {
    id: 5,
    image: portfolio05,
    title: 'Outdoor Tile'
  },
  {
    id: 6,
    image: portfolio06,
    title: 'Backsplash Tile'
  },
  {
    id: 7,
    image: portfolio07,
    title: 'Mosaic Tile'
  },
  // Add more team members as needed
];


const Portfolio = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    arrows: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <Container>
      <div className='teamSlider text-center'>
        <div className="other-text">
          <HeaderContainer>
            <Header>Our Tiles</Header>
          </HeaderContainer>
        </div>
      </div>
      <VerticalSpacer>
        <Slider {...settings}>
          {teamMembers.map((member) => (
            <div className="slide">
              <Image
                transition={{ duration: 0.3 }}
                $imageSrc={member.image}
              >
              </Image>
            </div>
          ))}
        </Slider>
      </VerticalSpacer>
    </Container>
  );
};

export default Portfolio;