import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/headerSections.js";

const StyledHeader = styled(Header)`
  ${tw`py-2 px-12 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-gray-800`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 bg-center bg-cover h-ThreeScreen px-8 sm:px-16`}
  background-image: url("${props => props.imgSrc}");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-35`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 m-auto h-full flex flex-col`;


const Content = tw.div`m-auto`;
const Heading = tw.h1`text-5xl sm:text-5xl md:text-6xl lg:text-8xl font-normal leading-none text-white border-2 border-white py-8 px-12 sm:px-24 text-center`;

export default ({
  title = "Gallery",
  imgSrc = "https://blog.emser.com/wp-content/uploads/2022/07/Omni-926x1024.jpg"
}) => {

  return (
    <>
      <StyledHeader home={true} />
      <Container imgSrc={imgSrc}>
        <OpacityOverlay />
        <HeroContainer>
          <Content>
            <Heading>{title}</Heading>
          </Content>
        </HeroContainer>
      </Container>
    </>
  );
};
